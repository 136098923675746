import { type FC, type ReactNode, useMemo } from 'react';
import { Card } from '../Card';
import { ExpandableSection } from './components/ExpandableSection';
import { CompletedTrainingCard } from './components/CompletedTrainingCard';
import styled from 'styled-components';
import { VoluntaryTrainingCard } from './components/VoluntaryTrainingCard';
import { MandatoryTrainingCard } from './components/MandatoryTrainingCard';
import AlertIcon from '../../assets/AlertIcon.svg';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { type EnrolledCampaignListResponse, useCampaignsQuery } from '../../apiHooks/useCampaignsQuery';
import { useState } from 'react';
import { LabelledToggle } from '../Toggle';
import type { LabelledToggleOption } from '../Toggle/LabelledToggle/LabelledToggle';
import { RTLFixSpan } from './utils/RTLFixSpan';
import { NoMandatoryTrainingsCard } from './components/NoMandatoryTrainingsCard';

export const TrainingWidget: FC = () => {
  const {
    data: { completed: completedCampaigns, mandatory: mandatoryCampaigns, voluntary: voluntaryCampaigns },
  } = useCampaignsQuery(true);

  const urgentCampaigns = useMemo(() => {
    if (!mandatoryCampaigns.length) {
      return [];
    }

    return mandatoryCampaigns.filter((campaign: EnrolledCampaignListResponse) => {
      const now: Date = new Date();
      const dueDate: Date = new Date(campaign.dueDate);
      const timeDifference = dueDate.getTime() - now.getTime();
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

      return daysDifference < 2;
    });
  }, [mandatoryCampaigns]);

  const toggleLabels: LabelledToggleOption[] = [
    {
      label: <NamedContentEntryText container="span" refKey="yourTraining" subKey="mandatory" />,
      value: 'mandatory',
      selected: true,
    },
    {
      label: <NamedContentEntryText container="span" refKey="yourTraining" subKey="voluntary" />,
      value: 'voluntary',
    },
    {
      label: <NamedContentEntryText container="span" refKey="yourTraining" subKey="completed" />,
      value: 'completed',
    },
  ];

  const [selectedTrainingType, setSelectedTrainingType] = useState(toggleLabels[0]?.value);
  const onFilterChange = (index: number) => {
    setSelectedTrainingType(toggleLabels[index]?.value);
  };

  return (
    <Card
      title={<NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="yourTraining" />}
      rightElem={
        !!urgentCampaigns.length && (
          <HeaderRightElement>
            <AlertIcon />
            <label>
              <NamedContentEntryText container={'span'} refKey="yourTraining" subKey="urgent" />:{' '}
              <RTLFixSpan>{urgentCampaigns.length}</RTLFixSpan>
            </label>
          </HeaderRightElement>
        )
      }
    >
      <FiltersBar>
        <LabelledToggle
          fullWidth
          labels={toggleLabels}
          onSelect={(selectedIndex: number) => onFilterChange(selectedIndex)}
        />
      </FiltersBar>
      <ExpandableSectionsContainer>
        <ExpandableSection
          title={
            <label>
              <NamedContentEntryText container="span" refKey="yourTraining" subKey="mandatory" />{' '}
              <RTLFixSpan>({mandatoryCampaigns.length})</RTLFixSpan>
            </label>
          }
          defaultOpen
          disabled
        >
          <ExpandableSectionContent>
            <MandatoryCampaignList
              campaigns={mandatoryCampaigns}
              render={(campaign: EnrolledCampaignListResponse) => (
                <MandatoryTrainingCard
                  key={campaign.id}
                  title={campaign.name}
                  completedModules={campaign.modulesProgress}
                  totalModules={campaign.modulesTotal}
                  dueDate={campaign.dueDate}
                  campaignUrl={campaign.campaignUrl}
                />
              )}
            />
          </ExpandableSectionContent>
        </ExpandableSection>
        <ExpandableSection
          title={
            <label>
              <NamedContentEntryText container="span" refKey="yourTraining" subKey="voluntary" />{' '}
              <RTLFixSpan>({voluntaryCampaigns.length})</RTLFixSpan>
            </label>
          }
          defaultOpen={!!voluntaryCampaigns.length}
        >
          <ExpandableSectionContent>
            <VoluntaryCampaignList
              campaigns={voluntaryCampaigns}
              render={(campaign: EnrolledCampaignListResponse) => (
                <VoluntaryTrainingCard
                  key={campaign.id}
                  title={campaign.name}
                  points={campaign.pointsAward}
                  campaignUrl={campaign.campaignUrl}
                />
              )}
            />
          </ExpandableSectionContent>
        </ExpandableSection>
        <ExpandableSection
          title={
            <label>
              <NamedContentEntryText container="span" refKey="yourTraining" subKey="completed" />{' '}
              <RTLFixSpan>({completedCampaigns.length})</RTLFixSpan>
            </label>
          }
          defaultOpen={!!completedCampaigns.length}
        >
          <ExpandableSectionContent>
            <CompletedCampaignList
              campaigns={completedCampaigns}
              render={(campaign: EnrolledCampaignListResponse) => (
                <CompletedTrainingCard key={campaign.id} title={campaign.name} campaignUrl={campaign.campaignUrl} />
              )}
            />
          </ExpandableSectionContent>
        </ExpandableSection>
      </ExpandableSectionsContainer>
      <MobileContainer>
        {/*Mandatory*/}
        {selectedTrainingType === 'mandatory' && (
          <MandatoryCampaignList
            campaigns={mandatoryCampaigns}
            render={(campaign: EnrolledCampaignListResponse) => (
              <MandatoryTrainingCard
                key={campaign.id}
                title={campaign.name}
                completedModules={campaign.modulesProgress}
                totalModules={campaign.modulesTotal}
                dueDate={campaign.dueDate}
                campaignUrl={campaign.campaignUrl}
              />
            )}
          />
        )}
        {/*Voluntary*/}
        {selectedTrainingType === 'voluntary' && (
          <VoluntaryCampaignList
            campaigns={voluntaryCampaigns}
            render={(campaign: EnrolledCampaignListResponse) => (
              <VoluntaryTrainingCard
                key={campaign.id}
                title={campaign.name}
                points={campaign.pointsAward}
                campaignUrl={campaign.campaignUrl}
              />
            )}
          />
        )}
        {/*Completed*/}
        {selectedTrainingType === 'completed' && (
          <CompletedCampaignList
            campaigns={completedCampaigns}
            render={(campaign: EnrolledCampaignListResponse) => (
              <CompletedTrainingCard key={campaign.id} title={campaign.name} campaignUrl={campaign.campaignUrl} />
            )}
          />
        )}
      </MobileContainer>
    </Card>
  );
};

type CampaignListProps = {
  campaigns: EnrolledCampaignListResponse[];
  render: (campaign: EnrolledCampaignListResponse) => ReactNode;
};

const MandatoryCampaignList: FC<CampaignListProps> = ({ campaigns, render }: CampaignListProps) => {
  if (!campaigns.length) {
    return <NoMandatoryTrainingsCard />;
  }

  return campaigns.map((campaign: EnrolledCampaignListResponse) => render(campaign));
};

const CompletedCampaignList: FC<CampaignListProps> = ({ campaigns, render }: CampaignListProps) => {
  if (!campaigns.length) {
    return (
      <NoTrainingsText>
        <NamedContentEntryText container={'span'} refKey={'yourTraining'} subKey={'noCompletedTrainings'} />
      </NoTrainingsText>
    );
  }

  return campaigns.map((campaign: EnrolledCampaignListResponse) => render(campaign));
};

const VoluntaryCampaignList: FC<CampaignListProps> = ({ campaigns, render }: CampaignListProps) => {
  if (!campaigns.length) {
    return (
      <NoTrainingsText>
        <NamedContentEntryText container={'span'} refKey={'yourTraining'} subKey={'noVoluntaryTrainings'} />
      </NoTrainingsText>
    );
  }

  return campaigns.map((campaign: EnrolledCampaignListResponse) => render(campaign));
};

const ExpandableSectionsContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ExpandableSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 10px;
`;

const HeaderRightElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.urgent};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
  text-transform: none;
`;

const FiltersBar = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const NoTrainingsText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
  text-align: center;
`;
