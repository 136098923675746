import { useMemo, type FC } from 'react';
import {
  DateLabel,
  DateLabelContainer,
  IconContainer,
  MainTrainingCard,
  ProgressBar,
  ProgressBarContainer,
  ProgressFill,
  Row,
  Title,
  TitleContainer,
  TrainingPrimaryButton,
} from '../TrainingCard';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { useLanguageContext } from '../../../../lib/hooks/useLanguageContext';
import { Icon, IconType } from '../../../Props/Icon/Icon';
import { CardStatus } from '../../enums/CardStatus';
import { getCardStatus } from '../../utils/getCardStatus';

type ManagerTeamTrainingCardProps = {
  title: string;
  dueDate: string;
  totalLearners: number;
  learnersCompletedTotal: number;
  estimatedCompletionTime: number;
};

export const ManagerTeamTrainingCard: FC<ManagerTeamTrainingCardProps> = ({
  title,
  dueDate,
  totalLearners,
  learnersCompletedTotal,
  estimatedCompletionTime,
}: ManagerTeamTrainingCardProps) => {
  const dd = useMemo(() => new Date(dueDate), [dueDate]);
  const cardStatus: CardStatus = getCardStatus(dd);
  const progress = totalLearners > 0 ? (learnersCompletedTotal / totalLearners) * 100 : 0;
  const [langCode] = useLanguageContext();

  return (
    <MainTrainingCard cardStatus={cardStatus}>
      <Row>
        <TitleContainer>
          <IconContainer>
            <Icon icon={IconType.GOAL_FLAG} width={24} height={24} className="rtl-flip" />
          </IconContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <DateLabelContainer cardStatus={cardStatus}>
          <StyledIconMobile width={16} height={16} icon={IconType.CALENDAR} />
          <DateLabel>{dd.toLocaleString(langCode, { month: 'short', day: 'numeric', year: 'numeric' })}</DateLabel>
          <StyledIcon width={16} height={16} icon={IconType.CALENDAR} />
        </DateLabelContainer>
      </Row>
      <Row>
        <ProgressBarContainer>
          <ProgressBar>
            <ProgressFill progress={progress} />
          </ProgressBar>
        </ProgressBarContainer>
        <StatsContainer>
          <StatsItem>
            <Icon width={16} height={16} icon={IconType.USER_COUNT} fill="none" />
            <span>
              <CompletedLearnersCount>{learnersCompletedTotal}</CompletedLearnersCount> / {totalLearners}
            </span>
          </StatsItem>
          <StatsItem>
            <Icon width={16} height={16} icon={IconType.CLOCK} fill="none" />
            <span>{estimatedCompletionTime} min</span>
          </StatsItem>
        </StatsContainer>
        <TrainingPrimaryButton
          onClick={() => {
            console.log('TODO: Implement Nudge Team Modal');
          }}
        >
          <NamedContentEntryText container="span" refKey="managerTeamCyberSecurityTraining" subKey="nudge" />
        </TrainingPrimaryButton>
      </Row>
    </MainTrainingCard>
  );
};

const StatsItem = styled.div`
  display: flex;
  gap: 6px;
  color: ${({ theme }) => theme.colors.normal};
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const CompletedLearnersCount = styled.span`
  font-weight: 600;
`;

const StyledIcon = styled(Icon)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledIconMobile = styled(Icon)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;
