import styled from 'styled-components';
import { Button } from '../../../Button';
import { CardStatus } from '../../enums/CardStatus';

export const TrainingCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.heading};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 600;
  margin-right: auto;
`;

export const TrainingPrimaryButton = styled(Button).attrs({ size: 'small' })`
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryContrast};
  transition: all 250ms ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 768px) {
    padding: 16px 24px;
  }
`;

export const TrainingSecondaryButton = styled(Button).attrs({ size: 'small' })`
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.trainingCardButton};
  color: ${({ theme }) => theme.colors.primary};
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primaryContrast};
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 8px;
`;

export const ProgressBar = styled.div`
  flex-grow: 1;
  height: 8px;
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.panelBackground};
  overflow: hidden;

  @media (max-width: 768px) {
    height: 16px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: unset;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: auto;
`;

export const MainTrainingCard = styled(TrainingCard).withConfig({
  shouldForwardProp: (prop) => prop !== 'cardStatus',
})<{ cardStatus: CardStatus }>`
  flex-direction: column;
  align-items: unset;
  background-color: ${({ cardStatus, theme }) =>
    cardStatus === CardStatus.urgent
      ? theme.colors.urgentBackground
      : cardStatus === CardStatus.warning
        ? theme.colors.warningBackground
        : theme.colors.trainingCardBackground};

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const DateLabelContainer = styled.label.withConfig({
  shouldForwardProp: (prop) => prop !== 'cardStatus',
})<{ cardStatus: CardStatus }>`
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  color: ${({ cardStatus, theme }) =>
    cardStatus === CardStatus.urgent
      ? theme.colors.urgent
      : cardStatus === CardStatus.warning
        ? theme.colors.waningText
        : theme.colors.normal};

  @media (max-width: 768px) {
    gap: 16px;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const DateLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const ProgressFill = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'progress',
})<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: ${({ theme }) => theme.colors.primary};
  transition: width 0.3s ease-in-out;
`;
