import type { FC } from 'react';
import CheckmarkGreen from '../../../../assets/CheckmarkGreen.svg';
import { IconContainer, TrainingSecondaryButton, Title, TrainingCard } from '../TrainingCard';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';

type CompletedTrainingCardProps = {
  title: string;
  campaignUrl?: string;
};

export const CompletedTrainingCard: FC<CompletedTrainingCardProps> = ({
  title,
  campaignUrl,
}: CompletedTrainingCardProps) => {
  return (
    <TrainingCardCompleted>
      <IconContainer>
        <CheckmarkGreen />
      </IconContainer>
      <Title>{title}</Title>
      <TrainingSecondaryButton
        disabled={!campaignUrl}
        onClick={() => {
          if (campaignUrl) {
            window.open(campaignUrl, '_blank', 'noopener,noreferrer');
          }
        }}
      >
        <NamedContentEntryText container="span" refKey="yourTraining" subKey="revisit" />
      </TrainingSecondaryButton>
    </TrainingCardCompleted>
  );
};

const TrainingCardCompleted = styled(TrainingCard)`
  opacity: 0.75;
`;
