import { type FC, useMemo } from 'react';
import {
  DateLabel,
  DateLabelContainer,
  IconContainer,
  MainTrainingCard,
  ProgressBar,
  ProgressBarContainer,
  ProgressFill,
  Row,
  Title,
  TitleContainer,
  TrainingPrimaryButton,
} from '../TrainingCard';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { useLanguageContext } from '../../../../lib/hooks/useLanguageContext';
import { Icon, IconType } from '../../../Props/Icon/Icon';
import { CardStatus } from '../../enums/CardStatus';
import { getCardStatus } from '../../utils/getCardStatus';

type MandatoryTrainingCardProps = {
  title: string;
  dueDate: string;
  totalModules: number;
  completedModules: number;
  campaignUrl?: string;
};

export const MandatoryTrainingCard: FC<MandatoryTrainingCardProps> = ({
  title,
  dueDate,
  totalModules,
  completedModules,
  campaignUrl,
}: MandatoryTrainingCardProps) => {
  const dd = useMemo(() => new Date(dueDate), [dueDate]);
  const cardStatus: CardStatus = getCardStatus(dd);
  const progress = totalModules > 0 ? (completedModules / totalModules) * 100 : 0;
  const dateText =
    cardStatus === CardStatus.urgent ? (
      <NamedContentEntryText container="span" refKey="yourTraining" subKey="urgent" />
    ) : (
      <NamedContentEntryText container="span" refKey="yourTraining" subKey="dueDate" />
    );
  const buttonText = progress ? (
    <NamedContentEntryText container="span" refKey="yourTraining" subKey="resume" />
  ) : (
    <NamedContentEntryText container="span" refKey="yourTraining" subKey="startNow" />
  );
  const [langCode] = useLanguageContext();

  return (
    <MainTrainingCard cardStatus={cardStatus}>
      <Row>
        <TitleContainer>
          <IconContainer>
            <Icon icon={IconType.GOAL_FLAG} width={24} height={24} className="rtl-flip" />
          </IconContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <DateLabelContainer cardStatus={cardStatus}>
          <Icon width={16} height={16} icon={IconType.CALENDAR} />
          <DateLabel>
            {dateText}: {dd.toLocaleString(langCode, { month: 'short', day: 'numeric', year: 'numeric' })}
          </DateLabel>
        </DateLabelContainer>
      </Row>
      <Row>
        <ProgressBarContainer>
          <ProgressBar>
            <ProgressFill progress={progress} />
          </ProgressBar>
          <ProgressText>
            {completedModules} / {totalModules}
          </ProgressText>
        </ProgressBarContainer>
        <TrainingPrimaryButton
          disabled={!campaignUrl}
          onClick={() => {
            if (campaignUrl) {
              window.open(campaignUrl, '_blank', 'noopener,noreferrer');
            }
          }}
        >
          {buttonText}
        </TrainingPrimaryButton>
      </Row>
    </MainTrainingCard>
  );
};

const ProgressText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.normal};
`;
