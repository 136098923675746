import type { FC } from 'react';
import { IconContainer, TrainingSecondaryButton, Title, TrainingCard } from '../TrainingCard';
import Play from '../../../../assets/Play.svg';
import { Coin } from '../../../Props';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';

type VoluntaryTrainingCardProps = {
  title: string;
  points?: number;
  campaignUrl?: string;
};

export const VoluntaryTrainingCard: FC<VoluntaryTrainingCardProps> = ({
  title,
  points,
  campaignUrl,
}: VoluntaryTrainingCardProps) => {
  return (
    <TrainingCard>
      <IconContainer>
        <Play className="rtl-flip" />
      </IconContainer>
      <Title>{title}</Title>
      {points && <Coin value={points} />}
      <TrainingSecondaryButton
        disabled={!campaignUrl}
        onClick={() => {
          if (campaignUrl) {
            window.open(campaignUrl, '_blank', 'noopener,noreferrer');
          }
        }}
      >
        <NamedContentEntryText container="span" refKey="yourTraining" subKey="startNow" />
      </TrainingSecondaryButton>
    </TrainingCard>
  );
};
