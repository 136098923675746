import React from 'react';
import ExternalLinkIcon from '../../../assets/ExternalLinkIcon.svg';
import styled from 'styled-components';

export type ExternalLinkButtonProps = {
  href: string;
  text: string;
  className?: string;
};

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ href, text, className }) => {
  return (
    <StyledLinkButton href={href} className={className}>
      <Content>
        <Text>{text}</Text>
        <ExternalLinkIcon />
      </Content>
    </StyledLinkButton>
  );
};

export default ExternalLinkButton;

const StyledLinkButton = styled.a`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  position: relative;
`;

const Text = styled.span`
  @media (max-width: 600px) {
    display: none;
  }
`;
