import type { FC } from 'react';
import styled from 'styled-components';
import { type CyberIQBadge, useCyberIQBadgesQuery } from '../../../apiHooks/useCyberIQBadgesQuery';
import ExternalLinkButton from '../../Button/ExternalLinkButton/ExternalLinkButton';
import { Icon, IconType } from '../Icon/Icon';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

type BadgeListProps = {
  badgeIds: string[];
  currentUserRankBelowTen?: boolean;
  enableExternalLinksView?: boolean;
  max?: number;
};

export const BadgeList: FC<BadgeListProps> = ({
  badgeIds,
  currentUserRankBelowTen = false,
  enableExternalLinksView = false,
  max = 10,
}) => {
  const { data: cyberIQBadges } = useCyberIQBadgesQuery();

  if (!cyberIQBadges) {
    return null;
  }

  const filteredBadges: CyberIQBadge[] = badgeIds
    .map((id: string) => cyberIQBadges.find((badge: CyberIQBadge): boolean => badge.id === id))
    .filter((item: CyberIQBadge | undefined): item is CyberIQBadge => item !== undefined);

  const renderExternalLinks = () => {
    if (enableExternalLinksView) {
      return (
        <ExternalLinkContainer>
          {filteredBadges.length > 0 && <ExternalLinkButton href="/" text="View all" className="view-all" />}
          {currentUserRankBelowTen && <ExternalLinkButton href="/" text="How to earn badges" className="earn-badges" />}
        </ExternalLinkContainer>
      );
    }
    return null;
  };

  const renderRemainingBadgesNumber = () => {
    if (badgeIds.length < max + 1) {
      return null;
    }

    const remainingBadges = badgeIds.length - max;

    return (
      <RemainingBadgesNumberContainer>
        <span>+</span>
        <span>{remainingBadges}</span>
      </RemainingBadgesNumberContainer>
    );
  };

  const renderBadges = () => {
    return filteredBadges.map((badge, index) =>
      index < max ? <img key={badge.id} src={badge.assetUrl} alt={`Badge ${badge.id}`} width={30} height={33} /> : null,
    );
  };

  const renderZeroState = () => {
    return (
      <NoBadgeContainer>
        <Icon icon={IconType.NO_BADGES} width={16} height={16} fill="transparent" />
        <NamedContentEntryText refKey="leaderboard" container="span" subKey="noBadges" />
      </NoBadgeContainer>
    );
  };

  return (
    <Container>
      <BadgeListContainer>
        {filteredBadges.length ? renderBadges() : renderZeroState()}
        {renderRemainingBadgesNumber()}
      </BadgeListContainer>
      {renderExternalLinks()}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  a.view-all {
    display: none;
  }
  &:hover a.view-all {
    display: flex;
  }
`;
const BadgeListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
const ExternalLinkContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const RemainingBadgesNumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.tableBorder};
  color: ${({ theme }) => theme.colors.heading};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  user-select: none;
`;

const NoBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background-color: ${({ theme }) => theme.colors.tableBorder};
  filter: brightness(1.1);
  padding: 4px 8px;
  user-select: none;
  color: ${({ theme }) => theme.colors.heading};
`;
